import React from 'react';
import {TodoList} from "./components/Todo/TodoList";
import './App.css';

function App() {
  return (
    <div className="todo-app">
        <TodoList/>
    </div>
  );
}

export default App;
